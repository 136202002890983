import {Card, Col, Image, Row} from 'react-bootstrap'
import optMain from '../../img/descuentos/farmacias/farmacias.png'
import Slider from "react-slick";
import devlinOpt from '../../img/descuentos/opticas/devlyn-opt.png'
import React from "react";

function Opticas(){

    const settings = {
        dots: true,
        speed: 800,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };

    return (
        <Row className="my-5">
            <Col xs="12" md="4">
                <div className="position-relative">
                    <label className="m-0 absolute-center text-descuento">Opticas</label>
                    <Image src={optMain} fluid/>
                    <div className="overlay"></div>
                </div>
            </Col>
            <Col xs="12" md="8">
                <Slider {...settings}>
                    <div>
                    <Card className="shadow-sm m-1 bg-body rounded-0 card-descuentos position-relative">
                        <Card.Body className="vcentered-area">
                            <div className="d-flex align-items-center h-100p">
                                <div className="me-2">
                                    <Image src={devlinOpt} fluid className="mh-100px"/>
                                </div>
                                <div>
                                    <span className="text-primary-1 fs-08 fw-bold d-block lh-1">20% anteojos graduados, armazones marcas seleccionadas, lentes de cristal o plástico, lentes de contado graduados.</span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    </div>
                    
                </Slider>
            </Col>
        </Row>
    )

}

export default Opticas