import React, {useContext, useState} from "react";
import {Button, Card, Col, Form} from "react-bootstrap";
import Select from "react-select";

import "../../styles/container.css";
import "../../styles/formularioLoggin.css";
import {VALIDATE_PATIENT} from "../../api/routes";
import Post from "../../api/post";
import ValidateAppointment from "../loader/ValidateAppointment";
import ComponentModalErrorSever from "../util/ComponentModalErrorSever";
import useUserIke from "../../hook/useUserIke";
import {authContext} from "../../context/AuthProvider";
import {Link} from "react-router-dom";

const AddMemberships = (props) => {
    const userIKe = useUserIke()
    const [membership, setMembership] = useState("")
    const [error, setError] = useState(false)
    const [type, setType] = useState(null)
    const [showModalServer, setShowModalServer] = useState(false)
    const [errorServer, setErrorServer] = useState(null)
    const [isSubmitting, setIsSubmiting] = useState(false)
    const [validated, setValidated] = useState(false);
    const { setAuthData } = useContext(authContext)

    const onClickCardAdd =() => {
        props?.onClickCardAdd()
    }

    const checkMembresia = (event) =>{
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            let d = {
                "account": {
                    "clcuentaIke": 2707
                },
                "externalId": membership,
                "company": {
                    "id": 1
                }
            }
            if (!userIKe[2] && userIKe[0]) {
                //console.log(userIKe[0])
                setIsSubmiting(true)
                Post({url: VALIDATE_PATIENT, data:d, access_token: userIKe[0], secure: true})
                    .then(response => {
                        setIsSubmiting(false)
                        if (response?.data?.state) {
                            if (Object.keys(response?.data?.data?.response).length > 0) {
                                setError(false)
                                props?.addMemberships(response?.data?.data?.response)
                            } else {
                                setError(true)
                            }
                        } else {
                            setError(true)
                        }
                    })
                    .catch(error => {
                        setError(true)
                        setIsSubmiting(false)
                        setShowModalServer(true)
                        if(error){
                            setErrorServer(error)
                        }
                    })
            }
        }
        setValidated(true);
    }

    const handleCloseModalServer = () => {
        setShowModalServer(false)
    }

  return (
      <>
          <Form noValidate  onSubmit={checkMembresia} validated={validated}  className="mt-3">
              <div className="d-block d-md-none text-center mb-4">
                  <label className='font-header'>
                      Programa Lockton
                  </label>
              </div>

              <Card className="shadow card-mebresia">
                  <Card.Body>
                      <h6 className="color-bluerey text-center my-3">
                          Para acceder a los servicios, ingresa tu código de acceso
                      </h6>
                      <div>
                          <Form.Group  as={Col} md="12">
                              <Form.Label></Form.Label>
                              <Form.Control type="text"
                                            name="membresia"
                                            id="membresia"
                                            placeholder="Número de Código de Acceso" required
                                            value={membership}
                                            onChange={e=>setMembership(e.target.value)}/>

                          </Form.Group>

                      </div>
                      <div>
                          <Button type="submit" className='py-2 px-4 mt-2 float-end ml-btn-5px' variant="primary">
                              Continuar
                          </Button>
                          <Link to="/memberships" onClick={onClickCardAdd} className="py-2 px-4 mt-2 float-end btn btn-danger">Cancelar</Link>
                      </div>
                  </Card.Body>
              </Card>
          </Form>
          {error &&
          <div className="d-flex color-red bold justify-content-evenly mt-5 font-16 mr-5px">
              <p>Tu código de acceso no se ha identificado, <br/>por favor comunícate al
                  <a href="tel:5590633706" className=""> 5590633706</a>
              </p>
          </div>
          }
          {isSubmitting && <ValidateAppointment texto={"Espere por favor..."} />}
          <ComponentModalErrorSever
              show={showModalServer}
              handleClose={handleCloseModalServer}
              error={errorServer}
          />
      </>
  );
};

export default AddMemberships;
