import { Card, Image, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NAV_PATIENT_PROFILE } from "../../api/routes";
import imgCalendarStars from '../../img/principales/confirm-cita.png'

function ModalEndAppointment({cita,show, setShow}){
    const handleClose = () =>setShow(false)

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
            <Modal.Body className="position-relative overflow-hidden rounded">
                <Card className="shadow-0 border-0 p-2 mb-2 bg-body rounded-3 text-center">
                    <Card.Body>
                        <Image src={imgCalendarStars} width='170px' className='m-auto'/>
                        <p className="font-40 color-bluerey w-700 mb-4">¡Listo!</p>
                        {
                            <p className="font-16 color-gray bold mt-3 mb-0">

                                Haz terminado una <span className='color-aquarium'>cita online </span> 
                                con Dr. {cita?.scheduleDTO?.name_doctor}

                            </p>
                        }        
                        <Link to={`/${NAV_PATIENT_PROFILE}`} className="link-oval bg-skyblue font-16 bold m-2">Ir a mi historial</Link>
                    </Card.Body>
                </Card>
            </Modal.Body>
        </Modal>
      );

}

export default ModalEndAppointment