import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import Get from "../api/get";
import {GET_APPOINTMENT_BY_ID} from "../api/routes";
import ValidateAppointment from "../components/loader/ValidateAppointment";
import InfoCard from "../components/utils/InfoCard";
import useUserIke from "../hook/useUserIke";
import LobyCita from "../components/cita/LobyCita";
import ComponentModalErrorSever from "../components/util/ComponentModalErrorSever";
import FooterMenu from "../components/FooterMenu";

function Reception(){
    const {id} = useParams()
    const [appointment, setAppointment] = useState(null)
    const [estado, setEstado] = useState("loading")
    const userIKe = useUserIke()
    const history = useHistory()
    const [alreadyIn, setAlreadyIn] = useState(true)
    const [errorServer, setErrorServer] = useState(null)
    const [showModalServer, setShowModalServer] = useState(false)

    
    useEffect(()=>{
            if(id && userIKe[0] && !userIKe[2] && alreadyIn){
                Get({url: `${GET_APPOINTMENT_BY_ID}/${id}`, access_token: userIKe[0], secure: true})
                .then(response => {
                    if (response.data?.state) {
                        let current_cita = response?.data?.data
                        setAppointment(current_cita)
                        setEstado("waiting")
                        setAlreadyIn(false)
                    }
                })
                .catch(error=>{
                    if(error){
                        setErrorServer(error)
                    }
                    setEstado("errorServer")
                    setAlreadyIn(false)
                })            
            }else if(id && userIKe[1]){
                setEstado("notFoundAppointment")
            }
    }, [userIKe[2]])

    const handleCloseModalServer = () => {
        setShowModalServer(false)
    }

    const onCompleteTimeStart = () =>{
        setEstado("enCita")
        history.push(`/conference/${btoa(id)}`)
    }

    const estadosMap = {
        loading: <ValidateAppointment texto={"Validando cita"} />,
        notFoundAppointment: <InfoCard texto="Lo sentimos no podemos encontrar tu cita"/>,
        waiting: <LobyCita appointment={appointment} onCompleteTimeStart={onCompleteTimeStart}/>,
        errorServer:  <ComponentModalErrorSever show={showModalServer} handleClose={handleCloseModalServer} error={errorServer}/>
    }

    return (
        <>
            {estadosMap[estado]}
            <FooterMenu/>
        </>
    )

}

export default Reception