import {Route, Switch, useRouteMatch} from "react-router-dom"
import Conference from "./Conference";
import Menu from "./Menu";
import RequestAppointment from "./RequestAppointment";
import BookAppointment from "./BookAppointment";
import PatientProfile from "./PatientProfile";
import Reception from "./Reception";
import React from "react";
import Discounts from "./Discounts";
import CheckUp from "./CheckUp";

function Site(){
    let {path} = useRouteMatch();

    return (

        <Switch>
            <Route exact path={`${path}menu`} component={Menu} />
            <Route exact path={`${path}request-appointment/:idEspecialidad`} component={RequestAppointment} />
            <Route exact path={`${path}book-appointment/:specialtyId`} component={BookAppointment} />
            <Route exact path={`${path}patient-profile`} component={PatientProfile} />
            <Route exact path={`${path}check-up`} component={CheckUp} />
            <Route exact path={`${path}conference/:id`} component={Conference} />
            <Route exact path={`${path}reception/:id`} component={Reception} />
            <Route exact path={`${path}discounts`} component={Discounts} />
        </Switch>
    )

}

export default Site