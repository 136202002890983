function PreguntaLineal({t, item, onHandleLinealAnswer, checkLinealStado}){
    
    return(
        <>
            <label className="font-weight-bold-600 font-size-09rem d-block">{item.title}</label>
            <div className="d-flex justify-content-between">
                <small>Nada</small>
                <small>Muy</small>
            </div>
            <div className="d-flex justify-content-between align-self-center li-encuesta">
                {
                    item.options.map((it, i)=>(
                        <div key={i} onClick={e=>onHandleLinealAnswer(it, item)}>
                            <span className={
                                checkLinealStado.filter(e=>e.id===item.id).length > 0 ?
                                checkLinealStado.filter(e=>e.id===item.id)[0].options[0]===it ? 'bg-info-1 text-white' : '' : ''
                            }>{it}</span>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default PreguntaLineal