import React, {useEffect, useRef, useState} from "react"
import {Button, Card, Col, Row} from "react-bootstrap"
import Get from "../../api/get"
import {ADD_DOCUMENT, DELETE_DOCUMENT, GET_DOCUMENT} from "../../api/routes"
import SimpleLoad from "../loader/SimpleLoad"
import {BsArrowClockwise} from "react-icons/bs";
import {RiDeleteBin2Line} from "react-icons/ri";
import {ImAttachment} from "react-icons/im";
import moment from "moment"
import {getIconDoc} from "../../utils/getIconDoc"
import useUserIke from "../../hook/useUserIke"
import PostFile from "../../api/postFile"
import Delete from "../../api/delete"

function SubirArchivos({cita, setShowModalError, setErrorServer}){
    const fileInputField = useRef(null);
    const [archivos, setArchivos] = useState([])
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(true)
    const [errorFile, setErrorFile] = useState('')
    const userIKe = useUserIke()

    useEffect(()=>{
        if(reload && cita && !userIKe[2]){
            setLoading(true)
            let url = `${GET_DOCUMENT}/${cita.id}`
            Get({url: url, access_token: userIKe[0],secure: true})
            .then(response=>{
                setArchivos(response.data.data.response)
                setReload(false)
                setLoading(false)
            })
            .catch(error=>{
                setReload(false)
                setLoading(false)
                setShowModalError(true)
                if(error.response){
                    setErrorServer(error.response)
                }
            })
        }
        
    },[reload, cita, userIKe[2]])

    const handleNewFileUpload = (e) =>{
        const file = e.target.files[0];
        let fileExtension = file.name.split('.').pop();
        if(fileExtension==='exe' || fileExtension==='apk'){
            setErrorFile('Documento no válido')
        }else{
            setLoading(true)
            let formData = new FormData()
            formData.append('file', file)
            formData.append('id', cita.id)
            formData.append('title', file.name)
            
            PostFile({url: ADD_DOCUMENT, data:formData, access_token: userIKe[0]})
            .then(response=>{
                setLoading(false)
                setReload(true)
            })
            .catch(error=>{                
                setLoading(false)
                setShowModalError(true)
                if(error.response){
                    setErrorServer(error.response)
                }
            })
        }
    }

    const seeDocument = (url) =>{
        window.open(url, "_blank", "toolbar=yes,resizable=yes")        
    }

    const deleteFile = (name, id) =>{
        //console.log('delete file')
        setLoading(true)
        Delete({url: `${DELETE_DOCUMENT}/${id}`, access_token: userIKe[0], secure: true})
        .then(response=>{
            if(response.data.state){
                setArchivos(archivos.filter(elem=>elem.id!==id))
            }else{
                setShowModalError(true)
                if(response){
                    setErrorServer(response)
                }
            }
            setLoading(false)
        })
        .catch(error=>{
            setLoading(false)
            setShowModalError(true)
            if(error.response){
                setErrorServer(error.response)
            }
        })
    }


    return (
        <div className="p-3 pt-4 position-relative">
            {loading && <SimpleLoad />}
            {
                archivos.map((item, i)=>(
                    <Card className="shadow-sm mb-3 cursor-pointer" key={i} onClick={e=>seeDocument(item.url)}>
                        <Card.Body className="py-1">
                            <div className="d-flex align-items-center">
                                <div className="pr-2">
                                    {getIconDoc(item.fileName)}
                                </div>
                                <div className="lh-1 me-auto">
                                    <span className="d-block m-0 color-989898 font-size-09rem">{item.fileName}</span>
                                    <small className="color-989898">{moment(item.createDate).format("MMMM DD, YYYY HH:mm")}</small>
                                </div>
                                {item.uploadUser.name==="PWA user" && <div className='ml-auto'>
                                    <span className="z-index-8 cursor-pointer" onClick={e=>{
                                        e.stopPropagation()
                                        deleteFile(item.fileName, item.id)
                                    }}><RiDeleteBin2Line className="color-red" /></span>
                                </div>}
                            </div>
                        </Card.Body>
                    </Card>
                ))
            }

            <Row>
                <Col xs="7" md="7">
                    <label className="font-weight-bold-600 color-blue-secundary font-size-09rem d-block">Agregar archivo</label>
                </Col>
                <Col xs="5" md="5" className="text-end mb-1">
                    <Button variant="primary" size="sm" onClick={e=>setReload(true)}><BsArrowClockwise /> Actualizar</Button>
                </Col>
                <Col xs="12" md="12">                    
                    {errorFile && <small className="color-red">{errorFile}</small>}
                </Col>

                <Col xs="12" md="12" className="position-relative">
                    <button type="button" className="btn-block btn-file">
                        <ImAttachment className="d-block m-auto color-989898 font-size-13rem"/>
                        <span className='d-block color-989898 font-size-09rem'>Subir archivo</span>
                    </button>
                    <input
                        type="file"
                        ref={fileInputField}
                        className="input-file"
                        onChange={handleNewFileUpload}
                    />                    
                </Col>
            </Row>
        </div>
    )

}

export default SubirArchivos