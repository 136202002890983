import {Col, Container, Row} from "react-bootstrap"
import React from "react";
import SemipolarLoadingg from "./SemipolarLoading";
import {Link} from "react-router-dom";
import {NAV_BOOK_APPOINTMENT} from "../../api/routes";

function LoaderOndemand({idEspecialidad}){

    return (
        <Container className="main pt-5rem">
            <Row className="my-5">
                <Col xs="12" md={{span: 6, offset: 3}}>
                    <h1 className="fs-10 text-primary">Estamos buscando <br /> disponibilidad</h1>
                </Col>
            </Row>
            <Row className="my-5">
                <Col className="text-center" xs="12" md={{span: 6, offset: 3}}>
                    <span className="bg-bluerey-asis text-white d-flex justify-content-center align-items-center wh-80 m-auto rounded-circle">
                        <SemipolarLoadingg />
                    </span>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col xs="12" md={{span: 4, offset: 4}}>
                    <small className="d-block text-center text-muted fw-bold">
                        *Dependiendo la disponibilidad del médico, el
                        tiempo de espera es de hasta 15 minutos.
                    </small>
                </Col>
            </Row>
            <Row className="my-5">
                <Col className="text-center" xs="12" md={{span: 6, offset: 3}}>
                    <h1 className="fs-10 color-blue-secundary font-weight-bold mb-4">¿Deseas agendar?</h1>
                    <Link to={`/${NAV_BOOK_APPOINTMENT}/${idEspecialidad}`} className="btn btn-primary font-16 bold m-2 py-2 px-4 mt-2"> Programar cita</Link>
                </Col>
            </Row>
        </Container>
    )
}

export default LoaderOndemand