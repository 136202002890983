import React, {useEffect, useState} from "react";
import {Button, Card, Col, Image, Modal, Row} from "react-bootstrap";
import foto from "../../img/foto.png";
import Slider from "react-slick";
import {APPOINTMENT_BY_PATIENT, CANCEL_APPOINTMENT} from "../../api/routes";
import useUserIke from "../../hook/useUserIke";
import Get from "../../api/get";
import moment from "moment";
import 'moment/locale/es';
import {Link, useHistory} from "react-router-dom";
import {isCitasProximas} from "../utils/isCitasProximas";
import HistoryAppointmentLoader from "../loader/HistoryAppointmentLoader";
import NextAppointmentLoader from "../loader/NextAppointmentLoader";
import StatusSpan from "../../utils/getStatus";
import Post from "../../api/post";
import ValidateAppointment from "../loader/ValidateAppointment";
import ComponentModalErrorSever from "../util/ComponentModalErrorSever";
import ModalSuccess from "../modals/ModalSuccess";

const SchedulesHistory = (props) => {
    const history = useHistory()
    const userIKe = useUserIke()
    const [schedules, setSchedules] = useState(null)
    const [cancelAppointment, setCancelAppointment] = useState(null)
    const [loading, setLoading] = useState(true)
    const [schedulesPastDue, setSchedulesPastDue] = useState(null)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isSubmitting, setIsSubmiting] = useState(false)
    const [errorServer, setErrorServer] = useState(null)
    const [showSuccess, setShowSuccess] = useState(false)
    const [showModalServer, setShowModalServer] = useState(false)
    const [slidesToShow, setSlidesToShow] = useState(1)
    const [slidesToScroll, setSlidesToScroll] = useState(1)
    const [rows, setRows] = useState(1)

    const settings = {
        dots: true,
        infinite: schedules?.length > 3,
        speed: 3000,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };

    const settingsHistory = {
        dots: true,
        speed: 800,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToScroll,
        initialSlide: 0,
        rows: rows,
        responsive: [
            {
              breakpoint: 480,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 2,
                  infinite: false,
                  rows: 2
              }
            }
        ]
    };

    useEffect(() => {
        if (props?.patient) {
            if (!userIKe[2] && userIKe[0]) {
                setLoading(true)
                let url = `${APPOINTMENT_BY_PATIENT}${props?.patient?.id}`
                console.log(url)
                Get({url: url, access_token: userIKe[0], secure: true})
                    .then(response => {
                        let schedulesArray = []
                        let schedulesPastDueArray = []
                        if (response?.data?.state) {
                            if (Object.keys(response?.data?.data).length > 0) {
                                response?.data?.data?.response?.forEach(it => {
                                    if (isCitasProximas(it.scheduleDTO.dateTimeInit)) {
                                        if (it.appointmentStatus === "Pagada") {
                                            schedulesArray.push(it)
                                        } else {
                                             if (schedulesPastDueArray.length < 1)
                                                schedulesPastDueArray.push(it)
                                        }
                                    } else {
                                         if (schedulesPastDueArray.length < 1)
                                            schedulesPastDueArray.push(it)
                                    }
                                })

                                setRows(schedulesPastDueArray?.length >= 6 ? 2 : 1)
                                if (schedulesPastDueArray.length >= 3) {
                                    setSlidesToShow(schedulesPastDueArray?.length >= 3 ? 3 : 1)
                                    setSlidesToScroll(schedulesPastDueArray?.length >= 3 ? 3 : 1)
                                } else {
                                    setSlidesToShow(schedulesPastDueArray?.length >= 2 ? 2 : 1)
                                    setSlidesToScroll(schedulesPastDueArray?.length >= 2 ? 2 : 1)
                                }

                                setSchedules(schedulesArray.reverse())
                                setSchedulesPastDue(schedulesPastDueArray)
                                setLoading(false)
                            }
                        }
                    })
                    .catch(errD => {
                        setLoading(false)
                        setSchedules(null)
                        setSchedulesPastDue(null)

                    })
            }
        }
    }, [userIKe[2]])

    const handleCloseModalServer = () => {
        setShowModalServer(false)
    }

    const confirmCancel = (appointmentParams) => {
        setCancelAppointment(appointmentParams)
        handleShow()
    }

    const cancelAppointmentAction = () =>{
        handleClose()
        setIsSubmiting(true)
        if (!userIKe[2] && userIKe[0] && cancelAppointment) {
            let data = {
                "id": cancelAppointment?.id,
                "appointmentStatus": cancelAppointment?.appointmentStatus
            }
            Post({url: CANCEL_APPOINTMENT, data: data,access_token: userIKe[0], secure: true})
                .then(response => {
                    if (response?.data?.state) {
                        setIsSubmiting(false)
                        setShowSuccess(true)
                        setTimeout(()=>{
                            setShowSuccess(false)
                            history.push(`/book-appointment/${cancelAppointment?.idSpeciality}`)
                        }, 4000)
                    }
                })
                .catch(error => {
                    setShowModalServer(true)
                    setErrorServer(error?.response)
                    setIsSubmiting(false)
                })
        }

    }

    return (
        <>
                <Row>
                    <Col>
                        <h4 className="text-primary-2 text-center fs-3">Estamos en todo momento contigo</h4>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col xs="12" md={{span: 10, offset: 1}}>
                    {loading ?
                        <div className="width-100 center">
                            <h4 className="text-primary-1 text-start">Citas Programadas</h4>

                            <Card className="shadow-sm p-0 mb-2 bg-body rounded-3 text-center" key={`nexLoad_0`}>
                                <Card.Body>
                                    <NextAppointmentLoader/>
                                </Card.Body>
                            </Card>

                            <h4 className="text-primary-1 text-start mt-4">Historial de Citas</h4>

                            <Card className="shadow-sm p-0 mb-2 bg-body rounded-3 text-center" key={`pastLoad_0`}>
                                <Card.Body>
                                    <HistoryAppointmentLoader/>
                                </Card.Body>
                            </Card>
                        </div>
                        :
                        <div className="width-100 center">
                            <h4 className="text-primary-1 text-start">Citas Programadas</h4>
                            {schedules?.length === 0 ?
                                    <Card className="shadow-sm p-2 mb-3 bg-body rounded-3 text-center" key={`schedule_0`}>
                                        <Card.Body>
                                            <h3>No tienes citas programadas</h3>
                                        </Card.Body>
                                    </Card>
                                    :
                                    <Slider {...settings}>
                                        {
                                            schedules?.map((it, i) => (
                                                <div className="item" key={`schedule_${i}`}>
                                                    <Card className="shadow-smmb-3 bg-body rounded-3 text-center">
                                                        <div className="rightCircle"  onClick={e=>confirmCancel(it)}>
                                                            <i className="icomoon icon-F__cerrar font-20 color-red"/>
                                                        </div>
                                                        <Card.Body>
                                                            <p className="font-18 text-primary-1 bold text-align-reset">
                                                                Próxima
                                                                cita {moment(it.scheduleDTO?.dateTimeInit, "YYYY-MM-DDTHH:mm:ss").fromNow()}
                                                            </p>
                                                            <p className="font-18 color-black bold text-align-reset text-align-doctor mb-0">
                                                                {it.scheduleDTO.name_doctor}
                                                            </p>
                                                            <p className="font-16 color-gray-strong bold text-align-reset mb-0">
                                                                {it.speciality.name}
                                                            </p>

                                                            <p className="font-16 color-gray bold text-align-reset mb-0 mt-1">
                                                                Fecha
                                                            </p>
                                                            <p className="font-18 color-black bold text-align-reset mb-1">
                                                                {moment(it.scheduleDTO?.dateTimeInit, "YYYY-MM-DDTHH:mm:ss").format("LL")}
                                                            </p>

                                                            <p className="font-16 color-gray bold text-align-reset mb-0 mt-1">
                                                                Hora
                                                            </p>
                                                            <p className="font-18 color-black bold text-align-reset mb-1">
                                                                {moment(it.scheduleDTO?.dateTimeInit, "YYYY-MM-DDTHH:mm:ss").format('LT')} - {moment(it.scheduleDTO?.dateTimeEnd, "YYYY-MM-DDTHH:mm:ss").format('LT')}
                                                            </p>
                                                            <Row className="border-top-gray">
                                                                <Col xs="12" className="mt-2 mb-0">
                                                                    <Link to={`/reception/${it?.id}`}
                                                                          className="link-reset font-16 color-green-past bold text-align-reset">Iniciar
                                                                        cita</Link>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </div>

                                            ))
                                        }
                                    </Slider>
                            }


                            <h4 className="text-primary-1 text-start mt-5">Historial de Citas</h4>
                            {schedulesPastDue?.length === 0 ?
                                    <Card className="shadow-sm p-2 mb-3 bg-body rounded-3 text-center" key={`pastDue_0`}>
                                        <Card.Body>
                                            <h3>No tienes citas realizadas</h3>
                                        </Card.Body>
                                    </Card>
                                    :
                                    <Slider {...settingsHistory} className="slideHistory mb-5">
                                        {
                                            schedulesPastDue?.map((it, i) => (
                                                <Card className={`shadow-sm m-1 bg-body rounded-3 card-citas-history position-relative ${schedulesPastDue?.length === 1 && 'card-onlyOne-history'}`}
                                                    key={`pastDue_${i}`}
                                                >
                                                    <StatusSpan status={it.appointmentStatus}/>
                                                    <Card.Body className="vcentered-area p-1">
                                                        <div className="d-flex align-items-center h-100p">
                                                            <div className="me-3">
                                                                <Image
                                                                    src={it.scheduleDTO.doctor.url_photo ? it.scheduleDTO.doctor.url_photo : foto}
                                                                    fluid className="m-auto radious-50 imgDoctor"
                                                                />
                                                            </div>
                                                            <div className="text-start">
                                                                <p className="font-16 text-primary-1 bold mb-1 mr-5px">
                                                                    {it.scheduleDTO.name_doctor}
                                                                </p>
                                                                <p className="font-16 color-gray bold mb-1">{it.speciality.name}</p>
                                                                <small className="text-primary-2">
                                                                    {moment(it.scheduleDTO?.dateTimeInit, "YYYY-MM-DDTHH:mm:ss").format("dddd,  D MMM YYYY, HH:mm")} hrs
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </Card.Body>

                                                </Card>
                                            ))
                                        }
                                    </Slider>
                            }
                        </div>
                    }
                    </Col>
                </Row>
            <Modal  {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered show={show} onHide={handleClose}>
                <Modal.Body>¿Estas seguro de cancelar tu cita?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={cancelAppointmentAction}>
                        Si
                    </Button>
                </Modal.Footer>
            </Modal>
            {isSubmitting && <ValidateAppointment texto={"Espere por favor..."} />}
            <ComponentModalErrorSever
                show={showModalServer}
                handleClose={handleCloseModalServer}
                error={errorServer}
            />
            <ModalSuccess show={showSuccess} setShow={setShowSuccess} msg={'La cita ha sido cancelada correctamente, en un instante podrás agendar una nueva cita'} />
        </>
    );
};

export default SchedulesHistory;
