import {Image, Modal} from "react-bootstrap"
import iconRecheck from '../../img/svg/iconcheck.svg'
import React from "react";

function ModalSuccess({show, setShow, msg}){

    const handleClose = () => setShow(false)

    return(
        <Modal show={show} onHide={handleClose} centered className="border-0">
            <Modal.Body className="p-0">
                <div className="d-flex position-relative overflow-hidden h-100p">
                    <Image src={iconRecheck} fluid className="img-recheck"/>
                    <h5 className="mb-0 text-app-success py-5">{msg ? msg: 'Solicitud exitosa'}</h5>
                </div>                
            </Modal.Body>
        </Modal>
    )

}
export default (ModalSuccess)