import axios from 'axios';
import { PASSWORD_IKE_CONNECT, URL_API, USER_IKE_CONNECT } from './contants';
import { LOGIN } from './routes';

const httpInstance = axios.create( {
    baseURL: URL_API  
});


httpInstance.interceptors.response.use(null, error => {
    if(error.response === undefined){
        alert("Hola estamos teniendo problemas de conexión con el servidor intente más tarde.")
    }else if(error.response.status===403){
        //console.log(error.response)
        const originalRequest = error.response.config
        originalRequest._retry = true
        let data = {
            username: USER_IKE_CONNECT,
            password: PASSWORD_IKE_CONNECT
        }
        const header = { headers: { 
            "Accept": "application/json",
            "Content-Type": "application/json"
        }}
        let urlLogin = `${URL_API}${LOGIN}`
        //console.log(urlLogin)
        return axios.post(urlLogin, data, header)
                    .then(response=>{
                        //console.log(response)
                        let storage = JSON.parse(localStorage.getItem("authIkePWA"));
                        //console.log(storage)
                        storage.ike_token = response.data.access_token
                        localStorage.setItem('authIkePWA', JSON.stringify(storage));
                        originalRequest.headers['Authorization'] = 'Bearer ' + response.data.access_token;
                        return axios(originalRequest);
                    })
       //window.localStorage.setItem('authIkePWA', null);
       //window.location.href=DEV_URL_LINK
       //window.localStorage.setItem('authIkePWA', null);
       //window.location.href=QA_URL_LINK
    }else if(error.response.status===503){
        alert("Servicio no disponible por el momento. Intente más tarde.")
    }else{
        return Promise.reject(error);    
    }    
});

export default httpInstance;