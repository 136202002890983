import React from "react";
import "../styles/container.css";
import "../styles/header.css";
import { useHistory } from "react-router-dom";


const FooterMenu = (props) => {
    const history = useHistory()

    const navigationProfile =() => {
      history.push('/patient-profile')
    }

    const navigationMenu =() => {
        history.push('/menu')
    }

  return (
      <div className="position-fixed footer">
        <div className="d-flex justify-content-evenly">
          <div className="text-center" onClick={navigationMenu}>
               <span className={`${props?.currentPage === 'menu' && 'color-bluerey-select'}`}>
                <i className="icomoon icon-F__Home font-30 d-block lh-1"></i>
                Inicio
              </span>              
          </div>
            <div className="text-center" onClick={navigationProfile}>
            <span className={`${props?.currentPage === 'profile' && 'color-bluerey-select'}`}>
                <i className="icomoon icon-F__Calendario font-30 d-block lh-1"></i>
                Mis citas
            </span>
          </div>
        </div>
      </div>
  );
};

export default FooterMenu;
