import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import SchedulesHistory from "../components/profile/SchedulesHistory";
import FooterMenu from "../components/FooterMenu";
import usePatient from "../hook/usePatient";


function PatientProfile(){
    const [state, setState] = useState({});
    const patient = usePatient()

    useEffect(() => {
        return () => { setState({}); };
    }, []);

    return (
        <>
            <Container className="main pt-5rem">
                <SchedulesHistory patient={patient}/>
            </Container>
            <FooterMenu currentPage={'profile'}/>

        </>
    );
}

export default PatientProfile