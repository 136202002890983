import { Form } from "react-bootstrap"

function PreguntaTexto({item, onHandleTextoAnswer}){
    return(
        <>
            <label className="font-weight-bold-600 font-size-09rem d-block">{item.title}</label>
            <Form.Control
                className="input-form"
                as="textarea"
                rows="5"
                onChange={e=>onHandleTextoAnswer(e.target.value, item)}
            ></Form.Control>
        </>
    )
}

export default PreguntaTexto