import React from "react";


function StatusSpan (status) {

    let statusValue = status.status === 'Pagada' ? 'Confirmada' : status.status
    let ccsClass = "space-status-confirmed"
    if (statusValue === "Confirmada"){
        ccsClass = "space-status-confirmed"
    }else if (statusValue === "Pendiente") {
        ccsClass = "space-status-pending"
    } else {
        ccsClass = "space-status-cancel"
    }

    return(
        <span className={ccsClass}>{statusValue}</span>
    )
}

export default StatusSpan