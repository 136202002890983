import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {Field, Formik} from "formik"
import * as Yup from 'yup';
import usePatient from "../../hook/usePatient";
import Get from "../../api/get";
import useUserIke from "../../hook/useUserIke";
import {GET_MUNICIPIO_BY_STATE, GET_STATES_BY_COUNTRY, SEND_CHECKOUT} from "../../api/routes";
import {COUNTRY_DEFAULT} from "../../constants/utils";
import ComponentModalErrorSever from "../util/ComponentModalErrorSever";
import ModalSuccess from "../modals/ModalSuccess";
import DatePicker, {registerLocale} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import es from 'date-fns/locale/es';
import moment from "moment";
import Post from "../../api/post";
import ValidateAppointment from "../loader/ValidateAppointment";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";

registerLocale('es', es)


const FormLab = () => {
  const patient = usePatient()
  const userIke = useUserIke()
  const history = useHistory()
  const [showModalServer, setShowModalServer] = useState(false)
  const [errorServer, setErrorServer] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [fecha, setFecha] = useState(null)
  const [initialData, setInitialData] = useState({
    externalId: '',
    name: '',
    lastName: '',
    email: '',
    gender: '',
    dateOfBirthday: '',
    state: '',
    municipio: '',
    company: '',
    account: '',
    checkupType: 'Check up'
  })
  const handleCloseModalServer = () => {
    setShowModalServer(false)
}

  const shemaValidate = Yup.object().shape({
    externalId: Yup.string()
      .required('Campo requerido'),
    name: Yup.string()
      .required('Campo requerido'),
    lastName: Yup.string()
      .required('Campo requerido'),
    email: Yup.string()
       .email('Correo inválido')
       .required('Campo requerido'),
    gender: Yup.string()
      .required('Campo requerido'),
    dateOfBirthday: Yup.string()
      .required('Campo requerido'),
    state: Yup.string()
      .required('Campo requerido'),    
    municipio: Yup.string()
       .required('Campo requerido')    
  });

  //efecto para el estado  y el municipio
  const [stateOpt, setStateOpt] = useState([])
  const [municipioOpt, setMunicipioOpt] = useState([])
  useEffect(()=>{
    if(userIke[0] && !userIke[2]){
      //estados
      Get({url: `${GET_STATES_BY_COUNTRY}/${COUNTRY_DEFAULT}`, access_token: userIke[0], secure: true})
      .then(response=>{
        if(response.data.state){
          setStateOpt(response.data.data.response)
        }
      })
      .catch(error=>{
        setShowModalServer(true)
        setErrorServer(error?.response)
      })
    }
  }, [userIke[2]])
  
  //efecto para el municipio
  const [idState, setIdState] = useState(null)
  useEffect(()=>{

    if(userIke[0] && !userIke[2] && idState){
      //estados
      Get({url: `${GET_MUNICIPIO_BY_STATE}/${idState}`, access_token: userIke[0], secure: true})
      .then(response=>{
        if(response.data.state){
          setMunicipioOpt(response.data.data.response)
        }
      })
      .catch(error=>{
        setShowModalServer(true)
        setErrorServer(error?.response)
      })
    }
  }, [userIke[2], idState])

  //efecto del paciente
  useEffect(()=>{
    if(patient){
      setIdState(patient?.state?.id)  //Javier siguele aca no me esta tomando el state
      setInitialData(prev=>({
        ...prev,
        account: patient.account,
        company: patient.company,
        checkupType: 'Check up',
        externalId: patient?.externalId,
        name: patient?.name,
        lastName: patient?.lastName,
        email: patient?.email,
        gender: patient?.gender
      }))
    }
  }, [patient])


  return (
    <>
      <Row>
        <Col className="text-center">
          <h5 className="text-primary-2 text-center">Estamos en todo momento contigo</h5>
          <h6 className="text-primary-1 text-center mt-4 fw-bold">Llena este formulario y te enviaremos por correo el formato para el laboratorio</h6>
        </Col>
      </Row>
      <Row>
        <Col className="text-center" xs="12" md="10">
          <Formik
            initialValues={initialData}
            validationSchema={shemaValidate}
            enableReinitialize
            onSubmit={(values, { setSubmitting, setFieldValue }) => {
              let data = Object.assign({}, values)
              let state = stateOpt.filter(e=>e.id===parseInt(values.state))
              let munic = municipioOpt.filter(e=>e.id===parseInt(values.municipio))
              data.state = state[0]
              data.municipio = munic[0]

              Post({url: SEND_CHECKOUT, data: data, access_token: useUserIke[0], secure: true})
              .then(response=>{
                setShowSuccess(true)
                setTimeout(()=>{
                  setShowSuccess(false)
                  history.push("/menu")
                }, 3000)
              })
              .catch(error=>{
                setShowModalServer(true)
                setErrorServer(error?.response)
              })
          }}
          >{({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldError
          })=>(
            <Form onSubmit={handleSubmit}>
                {isSubmitting && <ValidateAppointment texto={"Espere por favor..."} />}
                <Card className="shadow mt-4">
                  <Card.Body>
                    <Row>
                      <Col xs="12" md="6">
                        <Form.Group>
                          <Form.Label className="fs-09 fw-600">Código de acceso</Form.Label>
                          <Field
                            className={`form-control rounded-0 input-1 ${errors.externalId ? 'input-error' : ''}`}
                            name="externalId" 
                          />
                        </Form.Group>
                        {errors.externalId && <div className="invalid-feedback fs-08">{errors.externalId}</div>}
                      </Col>
                      <Col xs="12" md="6">
                        <Form.Group>
                          <Form.Label className="fs-09 fw-600">Correo electrónico</Form.Label>
                          <Field
                            className={`form-control rounded-0 input-1 ${errors.email ? 'input-error' : ''}`}
                            name="email" 
                          />
                        </Form.Group>
                        {errors.email && <div className="invalid-feedback fs-08">{errors.email}</div>}
                      </Col>
                      <Col xs="12" md="6">
                        <Form.Group>
                          <Form.Label className="fs-09 fw-600">Nombre</Form.Label>
                          <Field
                            className={`form-control rounded-0 input-1 ${errors.name ? 'input-error' : ''}`}
                            name="name" 
                          />
                        </Form.Group>
                        {errors.name && <div className="invalid-feedback fs-08">{errors.name}</div>}
                      </Col>
                      <Col xs="12" md="6">
                        <Form.Group>
                          <Form.Label className="fs-09 fw-600">Apellidos</Form.Label>
                          <Field
                            className={`form-control rounded-0 input-1 ${errors.lastName ? 'input-error' : ''}`}
                            name="lastName" 
                          />
                        </Form.Group>
                        {errors.lastName && <div className="invalid-feedback fs-08">{errors.lastName}</div>}
                      </Col>
                      <Col xs="12" md="6">
                        <Form.Group>
                          <Form.Label className="fs-09 fw-600">Sexo</Form.Label>
                          <Field
                            className={`form-control rounded-0 input-1 ${errors.gender ? 'input-error' : ''}`}
                            name="gender" 
                            as="select"
                          >
                            <option value="">Seleccionar</option>
                            <option value="Male">Masculino</option>
                            <option value="Female">Femenino</option>
                          </Field>
                        </Form.Group>
                        {errors.gender && <div className="invalid-feedback fs-08">{errors.gender}</div>}
                      </Col>
                      <Col xs="12" md="6">
                        <Form.Group>
                          <Form.Label className="fs-09 fw-600">Fecha nacimiento</Form.Label>
                          <DatePicker
                            selected={fecha} 
                            locale="es"
                            dateFormat="dd-MM-yyyy"
                            maxDate={new Date()}
                            showYearDropdown
                            scrollableYearDropdown
                            onChange={(date) => {
                              if(date){
                                setFecha(date)
                                setFieldValue("dateOfBirthday", moment(date).format("DD-MM-YYYY"))
                              }
                            }} 
                            className={`form-control rounded-0 input-1 ${errors.dateOfBirthday ? 'input-error' : ''}`}
                          />
                        </Form.Group>
                        {errors.dateOfBirthday && <div className="invalid-feedback fs-08">{errors.dateOfBirthday}</div>}
                      </Col>
                      <Col xs="12" md="6">
                        <Form.Group>
                          <Form.Label className="fs-09 fw-600">Estado</Form.Label>
                          <Form.Control
                            className={`form-control rounded-0 input-1 ${errors.state ? 'input-error' : ''}`}
                            as='select'
                            onChange={e=>{
                              setFieldValue('municipio', '',true)
                              setFieldValue("state", e.target.value)
                              setIdState(e.target.value)
                            }}
                          >
                            <option value="">Seleccionar</option>
                            {
                              stateOpt.map(item => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                              ))
                            }
                          </Form.Control>
                        </Form.Group>
                        {errors.state && <div className="invalid-feedback fs-08">{errors.state}</div>}
                      </Col>
                      <Col xs="12" md="6">
                        <Form.Group>
                          <Form.Label className="fs-09 fw-600">Municipio</Form.Label>
                          <Field
                            className={`form-control rounded-0 input-1 ${errors.municipio ? 'input-error' : ''}`}
                            name="municipio"
                            as="select"
                          >
                            <option value="">Seleccionar</option>
                            {
                              municipioOpt.map(item => (
                                <option key={item.id} value={item.id}>{item.dsMunDel}</option>
                              ))
                            }
                          </Field>
                        </Form.Group>
                        {errors.municipio && <div className="invalid-feedback fs-08">{errors.municipio}</div>}
                      </Col>
                      <Col xs="12" md="6">
                        <Form.Group>
                          <Form.Label className="fs-09 fw-600">Tipo</Form.Label>
                          <div className="form-control rounded-0 input-1">
                            {values.checkupType}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Row className="mt-4">
                  <Col xs="12" md="12" className="text-end">
                    <Button type="submit" className='py-2 px-4 mt-2 float-end ml-btn-5px' variant="primary">
                      Enviar
                    </Button>
                    <Link to="/menu" className="py-2 px-4 mt-2 float-end btn btn-danger">Cancelar</Link>
                  </Col>
                </Row>
            </Form>
          )}
          </Formik>
        </Col>
      </Row>
      <ComponentModalErrorSever
          show={showModalServer}
          handleClose={handleCloseModalServer}
          error={errorServer}
      />
      <ModalSuccess show={showSuccess} setShow={setShowSuccess} />
    </>
  );
};

export default FormLab;
