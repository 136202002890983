import { SemipolarLoading } from 'react-loadingg';

function SemipolarLoadingg(){
    const commonStyle = {
        margin: 'auto',
        left: 0,
        right: 0,
        top:0,
        bottom:0
    };

    return(
        <SemipolarLoading color={"#fbffe9"} style={commonStyle}/>
    )
}

export default  SemipolarLoadingg