import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {Field, Formik} from "formik"
import * as Yup from 'yup';
import usePatient from "../../hook/usePatient";
import useUserIke from "../../hook/useUserIke";
import {SEND_CHECKOUT_PET, VALIDATION_PET} from "../../api/routes";
import ComponentModalErrorSever from "../util/ComponentModalErrorSever";
import ModalSuccess from "../modals/ModalSuccess";
import {registerLocale} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import es from 'date-fns/locale/es';
import Post from "../../api/post";
import ValidateAppointment from "../loader/ValidateAppointment";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import SimpleLoad from "../loader/SimpleLoad";
import SemipolarLoading from "react-loadingg/lib/SemipolarLoading";

registerLocale('es', es)


const FormPet = () => {
  const patient = usePatient()
  const userIke = useUserIke()
  const history = useHistory()
  const [showModalServer, setShowModalServer] = useState(false)
  const [errorServer, setErrorServer] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [loadData, setLoadData] = useState(true)
  const [hasName, setHasName] = useState(false)
  const [hasRaza, setHasRaza] = useState(false)
  const [hasEspecie, setHasEspecie] = useState(false)
  const [initialData, setInitialData] = useState({
    externalId: '',
    name: '',
    lastName: '',
    company: '',
    account: '',
    razamascota: '',
    sexomascota: '',
    nombremascota: '',
    especiemascota: '',
    edadMascota: 0,
    checkupType: ''
  })

  const handleCloseModalServer = () => {
    setShowModalServer(false)
}

  const shemaValidate = Yup.object().shape({
    externalId: Yup.string()
      .required('Campo requerido'),
    name: Yup.string()
      .required('Campo requerido'),
    lastName: Yup.string()
      .required('Campo requerido'),
    edadMascota: Yup.string()
        .matches(/^[0-9]+$/, "Solo digitos")
        .min(0, 'Must be exactly 5 digits')
        .max(20, 'Must be exactly 5 digits')
      .required('Campo requerido'),
    especiemascota: Yup.string()
      .required('Campo requerido'),
    razamascota: Yup.string()
        .required('Campo requerido'),
    sexomascota: Yup.string()
        .required('Campo requerido'),
    checkupType: Yup.string()
        .required('Campo requerido'),
    nombremascota: Yup.string()
       .required('Campo requerido')    
  });


  //efecto del paciente
  useEffect(()=>{
    if(patient){
      setInitialData(prev=>({
        ...prev,
        account: patient.account,
        company: patient.company,
        externalId: patient?.externalId,
        name: patient?.name,
        lastName: patient?.lastName,
      }))

      let data = {
        account: {
          clcuentaIke: patient.account.clcuentaIke
        },
        externalId: patient.externalId,
        company: patient.company
      }
      Post({url: VALIDATION_PET, data, access_token: userIke[0], secure: true})
      .then(response=>{
        let animal = response.data.data.response[0]
        if(animal.nombremascota) setHasName(true)
        if(animal.razamascota) setHasRaza(true)
        if(animal.especiemascota) setHasEspecie(true)
        setInitialData(prev=>({
          ...prev,
          nombremascota: animal.nombremascota ?? "",
          razamascota: animal.razamascota ?? "",
          especiemascota: animal.especiemascota ?? ""
        }))
        setLoadData(false)
      })
      .catch(error=>{
        console.log(error)
        setLoadData(false)
      })
    }
  }, [patient])


  return (
    <>
      <Row>
        <Col xs="12" md={{span: 6, offset: 2}}>
          <h5 className="text-primary-2 text-center">Estamos en todo momento contigo</h5>
          <h6 className="text-primary-1 text-center mt-4 fw-bold">Llena este formulario y te enviaremos por correo el formato para el laboratorio</h6>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md={{span: 10, offset: 1}}>
          <Formik
            initialValues={initialData}
            validationSchema={shemaValidate}
            enableReinitialize
            onSubmit={(values, { setSubmitting, setFieldValue }) => {
              let dataFrom = Object.assign({}, values)
              let data = {
                edadMascota: dataFrom.edadMascota,
                especiemascota: dataFrom.especiemascota,
                nombremascota: dataFrom.nombremascota,
                razamascota: dataFrom.razamascota,
                sexomascota: dataFrom.sexomascota,
                patient: patient
              }
              data.patient.checkupType = dataFrom.checkupType

              Post({url: SEND_CHECKOUT_PET, data: data, access_token: useUserIke[0], secure: true})
              .then(response=>{
                if (response.data.state === true) {
                  setShowSuccess(true)
                  setTimeout(()=>{
                    setShowSuccess(false)
                    history.push("/menu")
                  }, 3000)
                } else {
                  setShowModalServer(true)
                  setErrorServer("Error interno")
                }
              })
              .catch(error=>{
                setShowModalServer(true)
                setErrorServer(error?.response)
              })
          }}
          >{({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldError
          })=>(
            <Form onSubmit={handleSubmit}>
                {isSubmitting && <ValidateAppointment texto={"Espere por favor..."} />}
                <Card className="shadow mt-4">
                  <Card.Body>
                    <Row>
                      <Col xs="12" md="4">
                        <Form.Group>
                          <Form.Label className="fs-09 fw-600">Código de acceso</Form.Label>
                          <Field
                            className={`form-control rounded-0 input-1 ${errors.externalId ? 'input-error' : ''}`}
                            name="externalId" 
                          />
                        </Form.Group>
                        {errors.externalId && <div className="invalid-feedback fs-08">{errors.externalId}</div>}
                      </Col>
                      <Col xs="12" md="4">
                        <Form.Group>
                          <Form.Label className="fs-09 fw-600">Nombre</Form.Label>
                          <Field
                            className={`form-control rounded-0 input-1 ${errors.name ? 'input-error' : ''}`}
                            name="name" 
                          />
                        </Form.Group>
                        {errors.name && <div className="invalid-feedback fs-08">{errors.name}</div>}
                      </Col>
                      <Col xs="12" md="4">
                        <Form.Group>
                          <Form.Label className="fs-09 fw-600">Apellidos</Form.Label>
                          <Field
                            className={`form-control rounded-0 input-1 ${errors.lastName ? 'input-error' : ''}`}
                            name="lastName" 
                          />
                        </Form.Group>
                        {errors.lastName && <div className="invalid-feedback fs-08">{errors.lastName}</div>}
                      </Col>
                      <Col xs="12" md="4">
                        <Form.Group>
                          <Form.Label className="fs-09 fw-600">
                            Nombre de la mascota {loadData && <div className="spinner-border text-primary wh-16" role="status"><span className="visually-hidden">Loading...</span></div>}
                          </Form.Label>
                          {
                            !loadData && 
                            <>
                              {
                                hasName ? 
                                <div className="form-control rounded-0 input-1 disabled">{values.nombremascota}</div> :
                                <Field
                                    className={`form-control rounded-0 input-1 ${errors.nombremascota ? 'input-error' : ''}`}
                                    name="nombremascota"
                                />
                              }
                            </>
                          }
                        </Form.Group>
                        {errors.nombremascota && <div className="invalid-feedback fs-08">{errors.nombremascota}</div>}
                      </Col>
                      <Col xs="12" md="4">
                        <Form.Group>
                          <Form.Label className="fs-09 fw-600">Especie de la mascota {loadData && <div className="spinner-border text-primary wh-16" role="status"><span className="visually-hidden">Loading...</span></div>}</Form.Label>
                          {
                            !loadData &&
                            <>
                              {
                                hasEspecie ? 
                                <div className="form-control rounded-0 input-1 disabled">{values.especiemascota}</div> :
                                <Field
                                    className={`form-control rounded-0 input-1 ${errors.especiemascota ? 'input-error' : ''}`}
                                    name="especiemascota"
                                />
                              }
                            </>
                          }
                        </Form.Group>
                        {errors.especiemascota && <div className="invalid-feedback fs-08">{errors.especiemascota}</div>}
                      </Col>
                      <Col xs="12" md="4">
                        <Form.Group>
                          <Form.Label className="fs-09 fw-600">Raza de la mascota {loadData && <div className="spinner-border text-primary wh-16" role="status"><span className="visually-hidden">Loading...</span></div>}</Form.Label>
                          {
                            !loadData &&
                            <>
                              {
                                hasRaza ? 
                                <div className="form-control rounded-0 input-1 disabled">{values.razamascota}</div> :
                                <Field
                                    className={`form-control rounded-0 input-1 ${errors.razamascota ? 'input-error' : ''}`}
                                    name="razamascota"
                                />
                              }
                            </>
                          }
                        </Form.Group>
                        {errors.razamascota && <div className="invalid-feedback fs-08">{errors.razamascota}</div>}
                      </Col>
                      <Col xs="12" md="4">
                        <Form.Group>
                          <Form.Label className="fs-09 fw-600">Edad de la mascota</Form.Label>
                          <input
                              className={`form-control rounded-0 input-1 ${errors.edadMascota ? 'input-error' : ''}`}
                              name="edadMascota" type="number" min="0" max="20"
                              value={values.edadMascota}
                              onChange={handleChange}
                              onBlur={handleBlur}
                          />
                        </Form.Group>
                        {errors.edadMascota && <div className="invalid-feedback fs-08">{errors.edadMascota}</div>}
                      </Col>
                      <Col xs="12" md="4">
                      <Form.Group>
                        <Form.Label className="fs-09 fw-600">Sexo</Form.Label>
                        <Field
                            className={`form-control rounded-0 input-1 ${errors.sexomascota ? 'input-error' : ''}`}
                            name="sexomascota"
                            as="select"
                        >
                          <option value="">Seleccionar</option>
                          <option value="Hembra">Hembra</option>
                          <option value="Macho">Macho</option>
                        </Field>
                      </Form.Group>
                      {errors.sexomascota && <div className="invalid-feedback fs-08">{errors.sexomascota}</div>}
                    </Col>
                      <Col xs="12" md="4">
                        <Form.Group>
                          <Form.Label className="fs-09 fw-600">Tipo de servicio</Form.Label>
                          <Field
                              className={`form-control rounded-0 input-1 ${errors.checkupType ? 'input-error' : ''}`}
                              name="checkupType"
                              as="select"
                          >
                            <option value="">Seleccionar</option>
                            <option value="Consulta">Consulta</option>
                            <option value="Estética">Estética</option>
                            <option value="Vacuna">Vacuna</option>
                          </Field>
                        </Form.Group>
                        {errors.checkupType && <div className="invalid-feedback fs-08">{errors.checkupType}</div>}
                      </Col>

                    </Row>
                  </Card.Body>
                </Card>
                <Row className="mt-4">
                  <Col xs="12" md="12" className="text-end">
                    <Button type="submit" className='py-2 px-4 mt-2 float-end ml-btn-5px' variant="primary">
                      Enviar
                    </Button>
                    <Link to="/menu" className="py-2 px-4 mt-2 float-end btn btn-danger">Cancelar</Link>
                  </Col>
                </Row>
            </Form>
          )}
          </Formik>
        </Col>
      </Row>
      <ComponentModalErrorSever
          show={showModalServer}
          handleClose={handleCloseModalServer}
          error={errorServer}
      />
      <ModalSuccess show={showSuccess} setShow={setShowSuccess} />
    </>
  );
};

export default FormPet;
