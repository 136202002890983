import React from "react";
import { Button, Image} from "react-bootstrap";
import logo from "../img/logo-header-lockton.png";
import { GiHamburgerMenu } from "react-icons/gi";
import SlideMenu from "./SlideMenu";
import { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false)
  
  return (
    <> 
      <div className="d-flex header justify-content-center align-items-center justify-content-between">
        <div>
          <Link to="/memberships">
            <Image src={logo} className='w-80'/>
          </Link>          
        </div>
        <div className="mr-auto ps-1 d-none d-md-block">
          <label className='font-header lh-1 m-0'>
            Programa Lockton
          </label>
        </div>
        <div>
          <Button variant="dark" onClick={e=>setShowMenu(true)} className="rounded-circle">
            <GiHamburgerMenu className="wh-2rem" />
          </Button>          
        </div>
      </div>

      <SlideMenu showMenu={showMenu} setShowMenu={setShowMenu}/>
    </>
  );
};

export default Header;
