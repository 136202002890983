import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Get from "../api/get";
import { GET_APPOINTMENT_BY_ID, GET_SURVEY_SIN_TOKEN } from "../api/routes";
import CitaConcluido from "../components/cita/CitaConcluido";
import EnCita from "../components/cita/EnCita";
import Waiting from "../components/cita/Waiting";
import ValidateAppointment from "../components/loader/ValidateAppointment";
import InfoCard from "../components/utils/InfoCard";
import useUserIke from "../hook/useUserIke";
import { compareDate } from "../utils/compareDate";
import { isBase64 } from "../utils/isBase64";
import { setTimeAppointmentSinToken } from "../utils/setTimeAppointmentSinToken";
import ComponentModalErrorSever from "../components/util/ComponentModalErrorSever";
import ModalEndAppointment from "../components/modals/ModalEndAppointment";
import ModalError from "../components/modals/ModalError";
import ModalEncuesta from "../components/modals/ModalEncuesta";

function Conference(){
    const {id} = useParams()
    const idAppointment = isBase64(id) ? atob(id) : null;
    const [jitsi, setJitsi] = useState(null)
    const [cita, setCita] = useState(null)
    const [hangUp, setHangUp] = useState(false)
    const [startMet, setStartMet] = useState(false)
    const [estado, setEstado] = useState("loading")
    const [textoStatusCita, setTextoStatusCita] = useState('')
    const [messageCita, setMessageCita] = useState('')
    const userIKe = useUserIke()
    const [alreadyIn, setAlreadyIn] = useState(true)
    const [errorServer, setErrorServer] = useState(null)
    const [showModalServer, setShowModalServer] = useState(false)
    const [showModalEnd, setShowModalEnd] = useState(false)

    //encuesta
    const [showEncuesta, setShowEncuesta] = useState(false)
    const [question, setQuestion] = useState([])

    //handle error server
    const [showModalError, setShowModalError] = useState(false)
    
    //effecto para la cita    
    useEffect(()=>{
        //console.log(userIKe)
            if(idAppointment && userIKe[0] && !userIKe[2] && alreadyIn){
                Get({url: `${GET_APPOINTMENT_BY_ID}/${idAppointment}`, access_token: userIKe[0], secure: true})
                .then(respCita=> {
                    let current_cita = respCita.data.data
                    //console.log(current_cita)
                    if (respCita.data?.state) {
                        let afterBefore = compareDate(current_cita.currentTime, current_cita.scheduleDTO?.dateTimeEnd)
                        let fechaInit =  compareDate(current_cita.currentTime, current_cita.scheduleDTO?.dateTimeInit)
                        let sameDay = moment(current_cita.currentTime, "DD/MM/YYYY HH:mm:ss").format("DD-MM-YYYY") ===  moment(current_cita.scheduleDTO?.dateTimeInit, "YYYY-MM-DDTHH:mm:ss").format("DD-MM-YYYY")

                        setCita(current_cita)
                        if(current_cita.appointmentStatus === 'Pendiente'){
                            setTextoStatusCita('Su cita se encuentra pendiente de aprobación')
                            setEstado("statusCita")
                        }
                        else if(current_cita.appointmentStatus === 'Cancelada'){
                            setTextoStatusCita('Su cita ha sido cancelada.')
                            setEstado("statusCita")
                        }
                        else if(current_cita.appointmentStatus === "NoAtendida" || (afterBefore === "terminado" && current_cita.dataVideoCallList.length === 0)){
                            console.log('concluido')
                            setTextoStatusCita("Su cita no ha sido atendida")
                            setEstado("statusCita")
                        }
                        else if(current_cita.finished || afterBefore === "terminado"){
                            setMessageCita('Su cita ha concluido')
                            setEstado("citaConcluido")
                        }
                        else if(fechaInit === "noTerminado" && sameDay){
                            //la cita aun no comienza
                            //contador para q comience la cita
                            setEstado("citaNoComienza")
                        }else if(fechaInit === "noTerminado" && !sameDay){
                            //la cita aun no comienza
                            setEstado("citaNoComienza")
                        }else{
                            setEstado("enCita")
                        }
                        setAlreadyIn(false)
                    }
                })
                .catch(error=>{
                    if(error){
                        setErrorServer(error)
                    }
                    setEstado("errorServer")
                    setAlreadyIn(false)
                })
                
                //obetner la encuesta
                Get({url: `${GET_SURVEY_SIN_TOKEN}/patient`, noToken: true})
                .then(response=>{
                    setQuestion(response.data.data.response)
                })
                .catch(error=>{
                    setShowModalError(true)
                    if(error.response){
                        setErrorServer(error.response)
                    }
                })


            }else if(idAppointment && userIKe[1]){
                setEstado("notFoundAppointment")
            }       
        
    }, [userIKe[2]])


    //effecto para cuando cualga la llamada
    useEffect(()=>{
        if(hangUp){
            setTimeAppointmentSinToken(cita.id, 'Patient', false, cita.dataVideoCallList)
            setHangUp(false)
        }
    }, [hangUp])

    //effecto para cuando empieza la llamada
    useEffect(()=>{
        //console.log('startMet')
        if(startMet){
            setTimeAppointmentSinToken(cita.id, 'Patient', true, cita.dataVideoCallList)
            setStartMet(false)
        }
    }, [startMet])

    const handleCloseModalServer = () => {
        setShowModalServer(false)
    }

    const handleAPI = (JitsiMeetAPI) => {
        setJitsi(JitsiMeetAPI)        

        if(cita.patientDTO.account.allowRecord){
            JitsiMeetAPI.addEventListener("videoConferenceJoined", () =>{            
                JitsiMeetAPI.executeCommand("startRecording", { mode: `file` })
                setStartMet(true)
            })
        }else{
            JitsiMeetAPI.addEventListener("videoConferenceJoined", () =>{            
                JitsiMeetAPI.executeCommand("stopRecording",  'file')
                setStartMet(true)
            })            
        }
        
        JitsiMeetAPI.addEventListener("videoConferenceLeft", () =>{
            setMessageCita('Su cita ha concluido')
            setEstado("citaConcluido")
        })

        //cuando cuelga
        JitsiMeetAPI.addEventListener("readyToClose", () => {
            setHangUp(true)
            setShowEncuesta(true)
        })
    }

    const onComplete = () =>{
        setTimeout(()=>{
            setHangUp(true)
            setMessageCita('Su cita ha concluido')
            setEstado("citaConcluido")
            setShowEncuesta(true)
        },1000)
    }  

    const onCompleteTimeStart = () =>{
        setStartMet(true)             
        setEstado("enCita")
    }

    const estadosMap = {
        loading: <ValidateAppointment texto={"Validando cita"} />,
        notFoundAppointment: <InfoCard texto="Lo sentimos no podemos encontrar tu cita"/>,
        statusCita: <InfoCard texto={textoStatusCita}/>,
        citaConcluido: <CitaConcluido messageCita={messageCita} cita={cita} setShowModalError={setShowModalError} setErrorServer={setErrorServer}/>,
        citaNoComienza: <Waiting  cita={cita} onCompleteTimeStart={onCompleteTimeStart}/>,
        enCita: <EnCita cita={cita} onComplete={onComplete} handleAPI={handleAPI} setShowModalError={setShowModalError} setErrorServer={setErrorServer}/>,
        errorServer:  <ComponentModalErrorSever show={showModalServer} handleClose={handleCloseModalServer} error={errorServer}/>
    }

    return (
        <>
            {estadosMap[estado]}
            <ModalEndAppointment show={showModalEnd} setShow={setShowModalEnd} cita={cita}/>
            <ModalError show={showModalError} setShow={setShowModalError} error={errorServer}/>
            <ModalEncuesta cita={cita} question={question} showEncuesta={showEncuesta} setShowEncuesta={setShowEncuesta} 
                setShowModalError={setShowModalError} setErrorServer={setErrorServer} setShowModalEndCita={setShowModalEnd}
            />
        </>        
    )
}

export default Conference