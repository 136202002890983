import { Button, Modal } from "react-bootstrap";
import { MdWarning } from "react-icons/md";

function ModalError({show, setShow, error}){

    const handleClose = () => setShow(false)

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Body className="position-relative overflow-hidden">
                <h5><MdWarning className="text-danger"/> Ooops!!! Algo ha pasado</h5>
                <ul className="list-unstyled">
                    {/* <li><span className="text-muted font-size-08rem">Error: {error?.status}</span></li> */}
                    <li>
                        <h6 className="m-0 text-black">{error?.data.error?.code}</h6>                        
                    </li>
                    <li>
                        <p>
                            {
                                error?.data.error?.message
                            }
                        </p>
                    </li>
                </ul>

                <div className="text-right">
                    <Button variant="light" onClick={handleClose}>Aceptar</Button>
                </div>
            </Modal.Body>
        </Modal>
    )

}

export default ModalError