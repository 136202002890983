import moment from "moment"

export const compareDate = (fechaServer, fecha2) =>{
    let f1 = moment(fechaServer, "DD/MM/YYYY HH:mm:ss")
    let f2 = moment(fecha2, "YYYY-MM-DDTHH:mm:ss")

    if(f1.isSameOrAfter(f2)){
        return "terminado"
    }
    return "noTerminado"
}