import httpInstance from "./server"

export default function Delete(props){
    if(!props.secure){
        return httpInstance.delete(props.url, {        
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
            }
        })            
    }else{
        return httpInstance.delete(props.url, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${props.access_token}`,
                "Accept": "application/json",
            }
        })
    }
    

}