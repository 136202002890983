import React from 'react';
import Header from './components/Header'
import { Switch, Route, Redirect } from "react-router-dom";
import Memberships from './pages/Memberships'
import Site from './pages/Site'
import PrivateRoute from './components/security/PrivateRoute'

function App() {
  return(
    <>
      <Header />
      <Switch>
          <Route exact path="/">
              <Redirect to="/memberships" />
          </Route>
          <Route path="/memberships" component={Memberships} />
          <PrivateRoute path="/" component={Site} /> 
      </Switch>      
    </>    
  )
}

export default App;
