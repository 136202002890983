import { createContext, useState, useEffect } from 'react';

export const authContext = createContext({});

const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({ loading: true, data: null });
    
    const setAuthData = (data) => {   
      setAuth({data: data});
    };
    

    useEffect(() => {
      setAuth({ loading: false, data: JSON.parse(localStorage.getItem('authIkePWA'))});
    }, []);

    useEffect(() => {
      localStorage.setItem('authIkePWA', JSON.stringify(auth.data));
    }, [auth]);
  
    return (
      <authContext.Provider value={{ auth, setAuthData }}>
        {children}
      </authContext.Provider>
    );
  };
  
  export default AuthProvider;