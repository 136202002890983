export const getStrTipoDoctor = (idSpeciality) =>{
    switch (idSpeciality){
        case 140:
            return "Veterinario"
        case 67:
            return "Psicólogo"
        case 142:
            return "Asesor"
        case 141:
            return "Técnico"
        case 59:
            return "Nutriólogo"
        default:
            return 'Médico'
    }
}