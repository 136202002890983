import React from "react";
import Row from "react-bootstrap/Row";
import {Button, Card, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Slider from "react-slick";

function MenuPets(props){

    const settingsMenu = {
        dots: true,
        speed: 800,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
       <>
           <Row>
           <Col>
               <Slider {...settingsMenu}>
                   <div>
                       <Card className="shadow-sm m-1 bg-body rounded-0 card-menu position-relative">
                           <Card.Body className="vcentered-area text-center">
                               <a href="tel:5550155071,3,1" className="text-decoration-none">
                                   <div className="mb-2">
                                       <i className='icomoon icon-F__Hospitalizacion color-blue-secundary font-size-30px wh-24px' />
                                   </div>
                                   <div className="mb-3 h-30px">
                                       <h6 className="text-center text-primary-1 m-0">Hospitalización</h6>
                                   </div>
                               </a>
                           </Card.Body>
                       </Card>
                   </div>
                   <div>
                       <Card className="shadow-sm m-1 bg-body rounded-0 card-menu position-relative">
                           <Card.Body className="vcentered-area text-center">
                               <a href="tel:5550155071,3,6" className="text-decoration-none">
                                   <div className="mb-2">
                                       <i className='icomoon icon-F__ConciergeMascotas color-blue-secundary font-size-30px wh-24px m-auto' />
                                   </div>
                                   <div className="mb-3 h-30px">
                                       <h6 className="text-center text-primary-1 m-0">Concierge mascota</h6>
                                   </div>
                               </a>
                           </Card.Body>
                       </Card>
                   </div>
                   <div>
                       <Card className="shadow-sm m-1 bg-body rounded-0 card-menu position-relative">
                           <Card.Body className="vcentered-area text-center">
                               <a href="tel:5550155071,3,7" className="text-decoration-none">
                                   <div className="mb-2">
                                       <i className='icomoon icon-F__ResponsabilidadCivil color-blue-secundary font-size-30px wh-24px m-auto' />
                                   </div>
                                   <div className="mb-3 h-30px">
                                       <h6 className="text-center text-primary-1 m-0">Seguro de responsabilidad civil</h6>
                                   </div>
                               </a>
                           </Card.Body>
                       </Card>
                   </div>
                   <div>
                       <Card className="shadow-sm m-1 bg-body rounded-0 card-menu position-relative">
                           <Card.Body className="vcentered-area text-center">
                               <a href="tel:5550155071,3,2" className="text-decoration-none">
                                   <div className="mb-2">
                                       <i className='icomoon icon-F__Vacuna color-blue-secundary font-size-30px wh-24px m-auto' />
                                   </div>
                                   <div className="mb-3 h-30px">
                                       <h6 className="text-center text-primary-1 m-0">Vacuna antirrábica</h6>
                                   </div>
                               </a>
                           </Card.Body>
                       </Card>
                   </div>
                   <div>
                       <Card className="shadow-sm m-1 bg-body rounded-0 card-menu position-relative">
                           <Card.Body className="vcentered-area text-center">
                               <a href="tel:5550155071,3,3" className="text-decoration-none">
                                   <div className="mb-2">
                                       <i className='icomoon icon-F__AsesoriaLegal color-blue-secundary font-size-30px wh-24px m-auto' />
                                   </div>
                                   <div className="mb-3 h-30px">
                                       <h6 className="text-center text-primary-1 m-0">Orientación legal y asistencia funeraria</h6>
                                   </div>
                               </a>
                           </Card.Body>
                       </Card>
                   </div>
               </Slider>
           </Col>
       </Row>
           <Row className="mt-5">
               <Col xs="12" md="6">
                   <Card className="shadow-sm bg-body rounded-0 card-menu-down position-relative mb-3">
                       <Card.Body>
                           <div>
                               <i className='icomoon icon-F__MedicoOnline font-size-24px color-blue-secundary' />
                           </div>
                           <Row>
                               <Col xs="5" md="5">
                                   <h6 className="text-primary-1">Orientación veterinaria</h6>
                               </Col>
                               <Col xs="7" md="7">
                                   <div className="mb-2">
                                       <a href="tel:5550155071,3,1" className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-2 text-primary-2 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__Telefono font-size-20px' /> LLamar
                                           </Button>
                                       </a>
                                   </div>
                                   <div className="mb-2">
                                       <Link to="/book-appointment/140" className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-1 text-primary-1 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__agendar font-size-20px' /> Agendar videoconsulta
                                           </Button>
                                       </Link>
                                   </div>
                                   <div>
                                       <Link to="/request-appointment/140" className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-1 text-primary-1 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__Reloj font-size-20px' /> Videoconsulta
                                           </Button>
                                       </Link>
                                   </div>
                               </Col>
                           </Row>
                       </Card.Body>
                   </Card>
               </Col>
               <Col xs="12" md="6">
                   <Card className="shadow-sm bg-body rounded-0 card-menu-down position-relative mb-3">
                       <Card.Body>
                           <div>
                               <i className='icomoon icon-F__ConsultaMascotas font-size-24px color-blue-secundary' />
                           </div>
                           <Row>
                               <Col xs="5" md="5">
                                   <h6 className="text-primary-1">Consultas, referencias y localización de mascotas</h6>
                               </Col>
                               <Col xs="7" md="7">
                                   <div className="mb-2">
                                       <a href="tel:5550155071,3,2" className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-2 text-primary-2 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__Telefono font-size-20px' /> LLamar
                                           </Button>
                                       </a>
                                   </div>
                                   <div className="mb-2">
                                       <Link to="/check-up" className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-1 text-primary-1 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__AgendaMascotas font-size-20px' /> Formato de consultas
                                           </Button>
                                       </Link>
                                   </div>
                                   <div className="">
                                       <Link to={{pathname:'/discounts', phoneNumber: '5550155071,3,2' }} className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-1 text-primary-1 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__listado font-size-20px' /> Ver descuentos
                                           </Button>
                                       </Link>
                                   </div>
                               </Col>
                           </Row>
                       </Card.Body>
                   </Card>
               </Col>
           </Row>
       </>
    )
}

export default MenuPets