import {Col, Container, Row} from "react-bootstrap"
import React, {useEffect, useState} from "react";
import FooterMenu from "../components/FooterMenu";
import MenuPets from "../components/menu/MenuPets";
import MenuIndividual from "../components/menu/MenuIndividual";
import MenuOldPerson from "../components/menu/MenuOldPerson";
import usePatient from "../hook/usePatient";

function Menu(){

    const [state, setState] = useState({});
    const patient = usePatient()

    useEffect(() => {
        return () => { setState({}); };
    }, []);


    useEffect(() => {
        return () => { setState({});  };
    }, []);

    return(
        <>
            <Container className="main pt-5rem">
                <Row>
                    <Col className="text-center">
                        <h4 className="text-primary-2 my-4 color-blue-secundary">Estamos en todo momento contigo</h4>
                        <h5 className="text-primary-1 color-blue-primary">Selecciona la asistencia que requieras</h5>
                    </Col>
                </Row>
                

                <Row className="my-5">
                    <Col xs="12" md="12">
                        {patient?.account?.clcuentaIke === 2591
                            ?
                            <MenuPets patient={patient}/>
                            :
                            patient?.account?.clcuentaIke === 2590
                                ?
                                <MenuOldPerson patient={patient}/>
                                :
                                <MenuIndividual patient={patient}/>
                        }
                    </Col>
                </Row>
            </Container>
            <FooterMenu currentPage={'menu'}/>
        </>
    )
}

export default Menu