import {Col, Container, Image, Row} from "react-bootstrap"
import {Link} from "react-router-dom"
import {NAV_BOOK_APPOINTMENT} from "../../api/routes";
import React from "react";
import TQuidoR from '../../img/tquido-rounded.png'

function NotAppovedAutoAgendar({idEspecialidad, texto}){


    return (
        <Container className="main pt-5rem">
            <Row className="my-5">
                <Col className="text-center" xs="12" md={{span: 6, offset: 3}}>
                    <Image src={TQuidoR} fluid className="img-TquidoR" />                        
                </Col>
            </Row>
            <Row className="my-5">
                <Col xs="12" md={{span: 10, offset: 1}} className="text-center">
                    <h1 className="fs-10 text-primary font-weight-bold mb-4">{texto}</h1>
                    <Link to={`/${NAV_BOOK_APPOINTMENT}/${idEspecialidad}`} className="btn btn-primary font-16 bold m-2 py-2 px-4 mt-2"> Programar cita</Link>
                </Col>
            </Row>    
        </Container>
    )
}

export default NotAppovedAutoAgendar