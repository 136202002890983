import { AiOutlineFileImage } from "react-icons/ai"
import { FaFileWord } from "react-icons/fa"
import { VscFilePdf } from "react-icons/vsc"

export const getIconDoc = fileName =>{
    let ext = fileName.split(".")[1].toLowerCase()

    switch(ext){
        case 'png':
            return <AiOutlineFileImage className="font-size-15rem text-success me-2" />
        case 'jpg':
            return <AiOutlineFileImage className="font-size-15rem text-success me-2" />
        case 'jpeg':
            return <AiOutlineFileImage className="font-size-15rem text-success me-2" />
        case 'heic':
            return <AiOutlineFileImage className="font-size-15rem text-success" />
        case 'pdf':
            return <VscFilePdf className="font-size-15rem text-danger me-2" />
        case 'docx':
            return <FaFileWord className="font-size-15rem text-primary me-2" />
        default: 
            return ''
    }    
}