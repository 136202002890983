import { Card } from "react-bootstrap"
import { MdAdd } from "react-icons/md"
import React from "react";

function CardAdd({onClickCard}){

    return (
        <Card className="shadow-sm card-mis-membresias mb-3 card-add-membresia" onClick={onClickCard}>
            <Card.Body className="flex-none">
                <div className="d-flex align-items-center justify-content-center">
                    <div className="pe-4">
                        <span>
                            <MdAdd className="btn-add-circle"/>
                        </span>
                    </div>
                    <div>
                        <h6 className="card-title text-primary m-0">Agregar código de acceso</h6>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default CardAdd