import moment from "moment"
import {Card, Col, Container, Row} from "react-bootstrap"
import {getStrTipoDoctor} from "../../utils/getStrTipoDoctor"
import FooterMenu from "../FooterMenu"
import SubirArchivos from "./SubirArchivos"

function CitaConcluido({messageCita, cita, setShowModalError, setErrorServer}){

    return (
        <>
            <Container className="main pt-5rem">
                <Row>
                    <Col xs="12" md={{span: 8, offset: 2}}>
                        <Card className="shadow-sm bg-danger">
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h6 className="mb-0 text-white">Su cita ha concluido</h6> 
                                    </div>
                                    <div>
                                        <h6 className="mb-0 text-white">{moment(cita.scheduleDTO.dateTimeInit, "YYYY-MM-DDTHH:mm:ss").format("DD MMMM YYYY HH:mm")}</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xs="12" md={{span: 8, offset: 2}}>
                        <span className="text-secondary">
                            {`${getStrTipoDoctor(cita.speciality.id)}: ${cita.scheduleDTO.name_doctor}`}
                        </span>
                        <Card>
                            <Card.Body className="bg-light">
                                <h3 className="text-center">{messageCita}</h3>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xs="12" md={{span: 8, offset: 2}}>
                        <SubirArchivos cita={cita} setShowModalError={setShowModalError} setErrorServer={setErrorServer}/>
                    </Col>
                </Row>
            </Container>
            <FooterMenu />
        </>
        
    )
}

export default CitaConcluido