import {Container} from "react-bootstrap"
import React, {useEffect, useState} from "react";
import AddMemberships from "../components/membresias/AddMemberships";
import ListMemberships from "../components/membresias/ListMemberships";

const Memberships = () =>{

    const [memberships, setMemberships] = useState([])
    const [listView, setListView] = useState(true)


    useEffect(() => {
        let mounted = true;
        if (mounted) {
            let membershipsStorage = JSON.parse(localStorage.getItem('memberships')) || [];
            setMemberships(membershipsStorage)
            if (memberships.length !== 0) {
                setListView(true)
            }
        }
    }, []);

    const onClickCardAdd = () => {
        setListView(!listView)
    }

    const addMemberships = (newsMemberships) => {
        let arrayList = memberships
        newsMemberships.forEach(elementItem => {
            let exists = arrayList.filter( item => item.id === elementItem.id)
            if(exists.length === 0) {
                arrayList.push(elementItem)
            }
        })
        setMemberships(arrayList)
        localStorage.setItem("memberships", JSON.stringify(arrayList))
        setListView(true)
    }

    const deleteMemberships = (itemMemberships) => {
        let arrayList = memberships
        arrayList= arrayList.filter( item => item !== itemMemberships)
        setMemberships(arrayList)
        localStorage.setItem("memberships", JSON.stringify(arrayList))
        setListView(true)
    }

    return (
        <>
            <Container className="main pt-5rem">
                {
                    listView ?
                     <ListMemberships onClickCardAdd={onClickCardAdd} memberships={memberships} deleteMemberships={deleteMemberships}/>
                     :
                     <AddMemberships addMemberships={addMemberships} onClickCardAdd={onClickCardAdd} />
                }
            </Container>
        </>
    );

}

export default Memberships