import React from "react";
import Row from "react-bootstrap/Row";
import {Button, Card, Col} from "react-bootstrap";
import {Link} from "react-router-dom";

function MenuIndividual(props){

    const hasEspeciality = (idParams) => {
        let result = false
        let exists = props?.patient?.account?.specialtyDurationList?.filter( item => item.especialty?.id === idParams)
        if(exists) {
            if(exists.length !== 0)
                result = true
        }

        return result
    }

    return (
       <>
           <Row className="align-items-center">
                <Col xs="12" md="4">
                    <div>
                           <Card className="shadow-sm m-1 bg-body rounded-0 card-menu position-relative">
                               <Card.Body className="vcentered-area text-center">
                                   <a href="tel:5590633706,3,3" className="text-decoration-none">
                                       <div className="mb-2">
                                           <i className='icomoon icon-F__Especialista color-blue-secundary font-size-30px wh-24px m-auto' />
                                       </div>
                                       <div className="mb-3 h-30px">
                                           <h6 className="text-center text-primary-1 m-0">Consulta en consultorio con médicos especialistas</h6>
                                       </div>
                                   </a>
                               </Card.Body>
                           </Card>
                       </div>
                </Col>
            </Row> 
           
           <Row className="mt-5">
               { hasEspeciality(54) &&
               <Col xs="12" md="6">
                   <Card className="shadow-sm bg-body rounded-0 card-menu-down position-relative mb-3">
                       <Card.Body>
                           <div>
                               <i className='icomoon icon-F__MedicoOnline font-size-24px color-blue-secundary' />
                           </div>
                           <Row>
                               <Col xs="5" md="5">
                                   <h6 className="text-primary-1">Orientación médica</h6>
                               </Col>
                               <Col xs="7" md="7">
                                   <div className="mb-2">
                                       <a href="tel:5590633706,1" className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-2 text-primary-2 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__Telefono font-size-20px' /> LLamar
                                           </Button>
                                       </a>
                                   </div>
                                   <div className="mb-2">
                                       <Link to="/book-appointment/54" className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-1 text-primary-1 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__agendar font-size-20px' /> Agendar videoconsulta
                                           </Button>
                                       </Link>
                                   </div>
                                   <div>
                                       <Link to="/request-appointment/54" className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-1 text-primary-1 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__Reloj font-size-20px' /> Videoconsulta
                                           </Button>
                                       </Link>
                                   </div>
                               </Col>
                           </Row>
                       </Card.Body>
                   </Card>
               </Col>
               }
               { hasEspeciality(67) &&
               <Col xs="12" md="6">
                   <Card className="shadow-sm bg-body rounded-0 card-menu-down position-relative mb-3">
                       <Card.Body>
                           <div>
                               <i className='icomoon icon-F__Psicologica_TQ font-size-24px color-blue-secundary' />
                           </div>
                           <Row>
                               <Col xs="5" md="5">
                                   <h6 className="text-primary-1">Orientación psicológica</h6>
                               </Col>
                               <Col xs="7" md="7">
                                   <div className="mb-2">
                                       <a href="tel:5590633706,2" className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-2 text-primary-2 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__Telefono font-size-20px' /> LLamar
                                           </Button>
                                       </a>
                                   </div>
                                   <div className="mb-2">
                                       <Link to="/book-appointment/67" className="text-decoration-none ">
                                           <Button variant="outline-primary" className="border-primary-1 text-primary-1 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__agendar font-size-20px' /> Agendar videoconsulta
                                           </Button>
                                       </Link>
                                   </div>
                                   <div>
                                       <Link to="/request-appointment/54" className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-1 text-primary-1 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__Reloj font-size-20px' /> Videoconsulta
                                           </Button>
                                       </Link>
                                   </div>
                               </Col>
                           </Row>
                       </Card.Body>
                   </Card>
               </Col>
               }
               { hasEspeciality(59) &&
               <Col xs="12" md="6">
                   <Card className="shadow-sm bg-body rounded-0 card-menu-down position-relative mb-3">
                       <Card.Body>
                           <div>
                               <i className='icomoon icon-F__Nutricional_TQ font-size-24px color-blue-secundary' />
                           </div>
                           <Row>
                               <Col xs="5" md="5">
                                   <h6 className="text-primary-1">Orientación nutricional</h6>
                               </Col>
                               <Col xs="7" md="7">
                                   <div className="mb-2">
                                       <a href="tel:5590633706,5" className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-2 text-primary-2 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__Telefono font-size-20px' /> LLamar
                                           </Button>
                                       </a>
                                   </div>
                                   <div className="mb-2">
                                       <Link to="/book-appointment/59" className="text-decoration-none ">
                                           <Button variant="outline-primary" className="border-primary-1 text-primary-1 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__agendar font-size-20px' /> Agendar videoconsulta
                                           </Button>
                                       </Link>
                                   </div>
                                   <div>
                                       <Link to="/request-appointment/54" className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-1 text-primary-1 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__Reloj font-size-20px' /> Videoconsulta
                                           </Button>
                                       </Link>
                                   </div>
                               </Col>
                           </Row>
                       </Card.Body>
                   </Card>
               </Col>
               }

            { hasEspeciality(142) &&
               <Col xs="12" md="6">
                   <Card className="shadow-sm bg-body rounded-0 card-menu-down position-relative mb-3">
                       <Card.Body>
                           <div>
                               <i className='icomoon icon-F__Checkup font-size-24px color-blue-secundary' />
                           </div>
                           <Row>
                               <Col xs="5" md="5">
                                   <h6 className="text-primary-1">Orientación pedagógica</h6>
                               </Col>
                               <Col xs="7" md="7">
                                   <div className="mb-2">
                                       <a href="tel:5590633706,6" className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-2 text-primary-2 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__Telefono font-size-20px' /> LLamar
                                           </Button>
                                       </a>
                                   </div>
                                   <div className="mb-2">
                                       <Link to="/book-appointment/59" className="text-decoration-none ">
                                           <Button variant="outline-primary" className="border-primary-1 text-primary-1 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__agendar font-size-20px' /> Agendar videoconsulta
                                           </Button>
                                       </Link>
                                   </div>
                                   <div>
                                       <Link to="/request-appointment/54" className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-1 text-primary-1 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__Reloj font-size-20px' /> Videoconsulta
                                           </Button>
                                       </Link>
                                   </div>
                               </Col>
                           </Row>
                       </Card.Body>
                   </Card>
               </Col>
               }


               <Col xs="12" md="6">
                   <Card className="shadow-sm bg-body rounded-0 card-menu-down position-relative mb-3">
                       <Card.Body>
                           <div>
                               <i className='icomoon icon-F__DescuentosYReferencias font-size-24px color-blue-secundary' />
                           </div>
                           <Row>
                               <Col xs="5" md="5">
                                   <h6 className="text-primary-1">Descuentos y referencias médicas</h6>
                               </Col>
                               <Col xs="7" md="7">
                                   <div className="mb-2">
                                       <a href="tel:5590633706,3,2" className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-2 text-primary-2 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__Telefono font-size-20px' /> LLamar
                                           </Button>
                                       </a>
                                   </div>
                                   <div className="">
                                       <Link to={{pathname:'/discounts', phoneNumber: '5590633706,3,2' }} className="text-decoration-none ">
                                           <Button variant="outline-primary" className="border-primary-1 text-primary-1 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__listado font-size-20px' /> Descuentos y referencias médicas
                                           </Button>
                                       </Link>
                                   </div>
                               </Col>
                           </Row>
                       </Card.Body>
                   </Card>
               </Col>               
           </Row>
       </>
    )
}

export default MenuIndividual