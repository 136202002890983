import {IoClose} from "react-icons/io5";
import {Link} from "react-router-dom";
import React from "react";

function SlideMenu({showMenu, setShowMenu}){

    return (
        <div className={`slide  ${showMenu ? 'slide-on' : 'slide-off'}`}>
            <div className="slide-wrapper">
                <div className="slide-content">
                    <IoClose className="close-icon wh-2rem" onClick={e=>setShowMenu(false)}/>

                    <div className="mt-2 p-3">
                        <ul className="list-unstyled">
                            <li className="mb-2">
                                <a href="tel:5590633706" className="text-decoration-none text-secondary fs-4 fw-bold">Contacto</a>
                            </li>
                            <li className="mb-2">
                                <a href="https://wellnessike.com" className="text-decoration-none text-secondary fs-4 fw-bold" rel="noreferrer" target="_blank">Plataforma wellness</a>
                            </li>
                            <li className="mb-2">
                                <a href="https://ikeasistencia.com/aviso-legal-y-de-privacidad/" rel="noreferrer" className="text-decoration-none text-secondary fs-4 fw-bold" target="_blank">Aviso Legal y Privacidad</a>
                            </li>
                            <li className="mb-2">
                                <Link className="text-decoration-none text-secondary fs-4 fw-bold" to="/memberships">Solicita tu asistencia</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SlideMenu