import { useEffect, useState } from "react";

function usePatient(){
    const [patient, setPatient] = useState(null);

    useEffect(() => {
        if(localStorage.getItem("selectedPatient")){
            let p = JSON.parse(localStorage.getItem('selectedPatient'))
            setPatient(p)
        }
    }, [])


    return patient
}

export default usePatient