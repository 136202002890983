import httpInstance from "./server";

export default function Put(props){
    const data = props.data
    let config = {}

    if(props.secure){
        config = {
            headers: { 
                "Authorization": `Bearer ${props.access_token}`,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        };
    }else{
        config = {
            headers: { 
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        };
    }

    return httpInstance.put(props.url, data, config)
}