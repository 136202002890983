import React, {useEffect, useState} from "react";
import {Card, Col, Container, Image, Row} from "react-bootstrap";
import {useParams} from "react-router";
import useUserIke from "../hook/useUserIke";
import ConfirmSuccess from "../components/cita/ConfirmSuccess";
import ScheduleSelector from "../components/cita/SchedulesSelector";
import {SPECIALTY_BY_ID} from "../api/routes";
import Get from "../api/get";
import ComponentModalErrorSever from "../components/util/ComponentModalErrorSever";
import FooterMenu from "../components/FooterMenu";
import calendarSVG from '../img/svg/calendar.svg'
import usePatient from "../hook/usePatient";

function BookAppointment(){
    const { specialtyId } = useParams()
    const patient = usePatient()
    const [especialidad, setEspecialidad] = useState( null)
    const userIKe = useUserIke()
    const [step, setStep] = useState('init');
    const [appointment, setAppointment] = useState(null)
    const [showModalServer, setShowModalServer] = useState(false)
    const [errorServer, setErrorServer] = useState(null)

    const [initialData, setInitialData] = useState({
        id: '',
        name: '',
        lastName: '',
        gender: '',
        dateOfBirthday: '',
        nationality: '',
        externalId: '',
        phoneNumber: '',
        email: '',
        bloodType: '',
        occupation:'',
        address: '',
        peso: '',
        talla: '',
        sintomas: '',
        dayAppointment: '',
        doctorId: '',
        hourDayId: '',
        hourDayHour: '',
        doctorName: '',
        especialidadName: '',
        especialidadId: '',
        account: '',
        currentInsurance: '',
        aceptarTerminos: true,
        linkPatient: null,
        location: '',
        neighborhood: '',
        identityDocumentType: '',
        documentNumber: '',
        choiceType: '',
        expediente: '',
        patientRelationship: '',
        hourInit: ''
    })

    useEffect(() => {
        let mounted = true;
        if(!userIKe[2] && userIKe[0] && mounted){
            let url = `${SPECIALTY_BY_ID}${specialtyId}`
            Get({url: url, access_token: userIKe[0], secure: true})
                .then(response=>{
                    if (response?.data?.state) {
                        setEspecialidad(response?.data?.data)
                    }
                })
                .catch(error=>{
                    if(error){
                        setErrorServer(error)
                    }
                })
        }
        return () => mounted = false;
    }, [userIKe[2], patient])

    const handleCloseModalServer = () => {
        setShowModalServer(false)
    }

    const handleNextStep = (newData, final=false) =>{
        setInitialData(prev=>({...prev, ...newData}))
    }
    const handlePrevStep = (newData) =>{
        setInitialData(prev=>({...prev, ...newData}))
    }


    const updateAppointment = (appointmentParams) => {
        if (appointmentParams){
            setAppointment(appointmentParams)
            setStep('confirmed')
        }
    }

    return (
        <>
            <Container className="main pt-5rem">
                <Row className="mb-5">
                    <Col className="text-center" xs="12" md="12">
                        <h1 className="text-primary fs-3 text-center">
                            Video consulta
                        </h1>
                    </Col>
                </Row>
                {step === 'init' && 
                <Row className="mb-5 d-md-none d-block">
                    <Col xs={{span: 8, offset: 2}} md={{span: 4, offset: 4}}>
                        <Card className="card-primary card-default">
                            <Card.Body className="py-4">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="pe-4">
                                        <Image src={calendarSVG} fluid className="calendarsvg-1" />
                                    </div>
                                    <div>
                                        <h4 className="text-white fs-4">Programar <br />consulta</h4>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>}
            
            
            
            {step === 'init' && 
                <ScheduleSelector next={handleNextStep}
                    setInitialData={setInitialData}
                    initialData={initialData}
                    prev={handlePrevStep}
                    speciality={especialidad}
                    patient={patient}
                    updateAppointment={updateAppointment}
                />
            }
            {step === 'confirmed' && 
                <Row>
                    <Col className="text-center" xs="12" md="6">
                        <ConfirmSuccess appointment={appointment}/>
                    </Col>
                </Row>
                
            }

            <ComponentModalErrorSever
                show={showModalServer}
                handleClose={handleCloseModalServer}
                error={errorServer}/>

        </Container>
            <FooterMenu currentPage={''}/>
        </>

    );
}

export default BookAppointment