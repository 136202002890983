export const LOGIN = "/api/users/login"
export const NAV_PATIENT_PROFILE = "patient-profile"
export const NAV_BOOK_APPOINTMENT = "book-appointment"

export const APPOINTMENT_DEMAND_LINK = "/api/appointment/on_demand"
export const GET_APPOINTMENT_BY_ID = "/api/appointment"
export const UPDATE_APPOINTMENT_SIN_TOKEN = "/api/appointment/public/update"

//Availability schedules
export const SCHEDULES_BY_SPECIALTY = "/api/schedule/createDynamicScheduleBySpeciality"
//Obtener especialidad
export const SPECIALTY_BY_ID = "/api/specialty/"
//agendar cita
export const ADD_APPOINTMENT ="/api/appointment/addAppointment"
//Lista de citas por paciente
export const APPOINTMENT_BY_PATIENT ="/api/appointment/show_by_patient/"

//encuestas
export const GET_SURVEY_SIN_TOKEN = "/api/survey/public/show"
export const SURVEY_SAVE_SIN_TOKEN = "/api/surveyanswer/public/save_survey"

//documentos
export const DELETE_DOCUMENT ="/api/document/delete"
export const ADD_DOCUMENT = "/api/document"
export const GET_DOCUMENT = "/api/document/findByAppointment"

//Membresias
export const VALIDATE_PATIENT = "/api/patient/validationPatient"

//Cancelar cita
export const CANCEL_APPOINTMENT ="/api/appointment/cancel"

//estados
export const GET_STATES_BY_COUNTRY = "/api/state/list_active"

//municipios
export const GET_MUNICIPIO_BY_STATE = "/api/municipio/list_active"

//checkout
export const SEND_CHECKOUT = "/api/appointment/checkup"
export const SEND_CHECKOUT_PET = "/api/appointment/checkupVet"

export const VALIDATION_PET = "/api/patient/validationPet"
