import { useState } from "react"
import { Alert, Button, Col, Modal, Row } from "react-bootstrap"
import PreguntaLineal from "../encuesta/PreguntaLineal"
import PreguntaOpcionMultiple from "../encuesta/PreguntaOpcionMultiple"
import PreguntaSeleccionIndividual from "../encuesta/PreguntaSeleccionIndividual"
import PreguntaTexto from "../encuesta/PreguntaTexto"
import SimpleLoad from "../loader/SimpleLoad"
import { FaTimes } from "react-icons/fa";
import Post from "../../api/post"
import { SURVEY_SAVE_SIN_TOKEN } from "../../api/routes"

function ModalEncuesta({cita, question, showEncuesta, setShowEncuesta, setShowModalError, setErrorServer, setShowModalEndCita}){
    const [checkLinealStado, setCheckLinealStado] = useState([])
    const [answer, setAnswer] = useState([])
    const [errorAnswerSurvey, setErrorAnswerSurvey] = useState(false)
    const [saveSurvey, setSaveSurvey] = useState(false)

    const handleClose = () =>{
        setSaveSurvey(true)
        let d = {
            id: cita.id,
            surveys: []
        }
        //enviamos la encuesta vacia
        Post({url: SURVEY_SAVE_SIN_TOKEN, data: d, secure: false})
        .then(response=>{
            setSaveSurvey(false)
            setShowEncuesta(false)
            setShowModalEndCita(true)
        })
        .catch(error=>{
            setSaveSurvey(false)
            setShowModalError(true)
            if(error.response){
                setErrorServer(error.response)
            }
        })
    }

    const fillAnswer = (obj) =>{
        let findIndex = answer.findIndex(ele=>ele.id===obj.id)
        if(findIndex >= 0){
            let arr = [...answer]
            arr[findIndex] = obj
            setAnswer(arr)
        }else{
            let arr = [...answer]
            arr.push(obj)
            setAnswer(arr)
        }
    }

    const onHandleLinealAnswer = (respuesta, item) =>{        
        let obj = JSON.parse(JSON.stringify(item));
        obj.options = [respuesta]
        fillAnswer(obj)
        let arr = [...checkLinealStado]        
        let findIndex = answer.findIndex(ele=>ele.id===obj.id)
        if(findIndex>=0){
            let findi = arr.findIndex(el=>el.id===answer[findIndex].id)
            if(findi>=0){
                arr[findi] = obj
                setCheckLinealStado(arr)
            }
        }else{
            arr.push(obj)
            setCheckLinealStado(arr)            
        }
    }

    const onHandleMultipleAnswer = (respuesta, checked, item) =>{
        let obj = JSON.parse(JSON.stringify(item))        
        let findIndexObjCreate = answer.findIndex(el=>el.id===item.id)
        if(checked){
            if(findIndexObjCreate>=0){
                obj = answer[findIndexObjCreate]
                obj.options.push(respuesta)
            }else{
                obj.options = [respuesta]
            }
        }else{
            if(findIndexObjCreate>=0){
                let arr = answer[findIndexObjCreate].options.filter(el=>el!==respuesta)
                obj.options = arr
            }   
        }
        fillAnswer(obj)
    }

    const onHandleIndividualAnswer = (respuesta, item) =>{
        let obj = JSON.parse(JSON.stringify(item))
        let findIndexObjCreate = answer.findIndex(el=>el.id===item.id)
        if(findIndexObjCreate>=0){
            obj = answer[findIndexObjCreate]
            obj.options = [respuesta]
        }else{
            obj.options = [respuesta]
        }
        fillAnswer(obj)
    }

    const onHandleTextoAnswer = (texto, item) =>{
        let obj = item;
        obj.options = [texto]
        fillAnswer(obj)        
    }

    const sendEncuesta = e =>{
        if(answer.length === 0){
            setErrorAnswerSurvey(true)
        }else{
            setErrorAnswerSurvey(false)
            //console.log(answer)
            setSaveSurvey(true)
            let d = {
                id: cita.id,
                surveys: answer
            }      
            Post({url: SURVEY_SAVE_SIN_TOKEN, data: d, header: false})
            .then(response=>{
                setSaveSurvey(false)
                setShowEncuesta(false)
                setShowModalEndCita(true)
            })
            .catch(error=>{
                setSaveSurvey(false)
                setShowModalError(true)
                if(error.response){
                    setErrorServer(error.response)
                }
            })
        }        
    } 

    return (
        <>
            <Modal show={showEncuesta} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
                {saveSurvey && <SimpleLoad />}
                
                <Modal.Body>  
                    <div className="py-2 text-end"><FaTimes onClick={handleClose} className="cursor-pointer"/></div>              
                    
                    <Row>
                        <Col xs="12" md="12">
                            {errorAnswerSurvey && 
                            <Alert variant="danger">Al menos debes responder una pregunta para enviar la encuesta</Alert>
                            }
                            <h3 className="text-black">Encuesta de Satisfacción</h3>                        
                        </Col>

                        {
                            question.map((item,i)=>(
                                <Col xs="12" md="12" key={i} className="mb-3">
                                    {
                                        item.questionType.nombreTipoPregunta==='Lineal' ?
                                        <PreguntaLineal item={item} onHandleLinealAnswer={onHandleLinealAnswer} checkLinealStado={checkLinealStado}/> :
                                        item.questionType.nombreTipoPregunta==='OpcionMultiple' ?
                                        <PreguntaOpcionMultiple item={item} onHandleMultipleAnswer={onHandleMultipleAnswer}/> :
                                        item.questionType.nombreTipoPregunta==="SeleccionIndividual" ?
                                        <PreguntaSeleccionIndividual item={item} onHandleIndividualAnswer={onHandleIndividualAnswer}/> :
                                        <PreguntaTexto item={item} onHandleTextoAnswer={onHandleTextoAnswer}/>
                                    }
                                </Col>
                            ))
                        }
                        
                    </Row>                
                    <hr className="mb-0 dashed"/>
                    <Row>
                        <Col xs="12" md="12">
                            <div className="text-center text-md-right bg-light py-3 px-3">                            
                                <Button variant="primary"className="ok-primary" onClick={sendEncuesta}>
                                    Enviar
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalEncuesta